import { Component, Watch } from 'vue-property-decorator'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { fixPrice } from '@/utils/general'
import { PersonFormatted } from '@/store/persons/person'
import { GForm } from '@/components/forms/GForm'
import { plainToInstance } from 'class-transformer'
import { Person } from '@/entities/persons'
import { ConsultRutInterface } from '@/store/persons/state'
import { Debounce } from '@/utils/decorators'

@Component({
  methods: {
    fixPrice,
    ...mapActions('persons', ['getPersonById', 'lookFieldsRequired', 'embedPerson']),
    ...mapMutations('persons', ['setFoundPerson']),
    ...mapActions('persons/address', ['addressUpdateStatus']),
    ...mapActions('persons/bank', ['updateAccountState']),
  },
  computed: {
    ...mapGetters('persons', ['getFoundPerson', 'personId', 'isAddressRequired', 'isAccountRequired', 'rutInfo', 'typeInfo', 'getEmbedPerson']),
  },
})
export class BaseCustomForm extends GForm {
  getFoundPerson!: PersonFormatted | null;
  isAddressRequired!: boolean;
  isAccountRequired!: boolean;
  getEmbedPerson!: any;
  rutInfo!: ConsultRutInterface;
  typeInfo!: string
  updateAccountState!: (idPersonAccount: number) => Promise<void>;
  addressUpdateStatus!: (id: number) => Promise<void>;
  embedPerson!: (payload) => void
  getPersonById!: (id: number) => Promise<void>;
  lookFieldsRequired!: (id: number) => void;
  setFoundPerson!: (person: PersonFormatted | null) => void;

  personEntity = plainToInstance(Person, {})
  accountHolder = ''
  personId!: number | null;
  redirect = false;
  isUser = false

  get personIdComputed () {
    const {
      $route: {
        params: { id: routeId },
      },
    } = this

    const { id, uid, model, personId: storeId } = this
    if (model === 'Person') return parseInt(uid)
    const personId = model ? id || routeId || storeId || parseInt(uid) : storeId || parseInt(uid)

    return personId !== 'created' ? personId : null
  }

  @Watch('$route', { immediate: true, deep: true })
  @Debounce(1500)
  async onRouteChange () {
    const { personIdComputed } = this
    if (personIdComputed) {
      await this.getPersonById(parseInt(personIdComputed))
    }
  }

  @Watch('personIdComputed', { immediate: true })
  async onPersonIdChange (id) {
    if (!id) return

    await this.findPersonInGenio(id)
  }

  @Watch('getFoundPerson', { immediate: true, deep: true })
  async onChange (val) {
    if (!val?.id) return
    this.setAccount(val)
    await this.findPersonInGenio(val.id)
    await this.findIfPersonIsUser(val.id)
  }

  async findIfPersonIsUser (id) {
    if (!id) return
    const user = await this.fetchData({
      query: { name: 'findLite', model: 'User' },
      filter: { person: { id: { _eq: id } } },
    })
    this.isUser = Boolean(user?.length)
  }

  async findPersonInGenio (id) {
    const person = await this.fetchData({
      query: { name: 'fetch', model: 'Person', params: { id } },
      force: true,
    })

    if (person.type.name === 'system' && !this.isSysOps && !this.$route?.path.includes('sysop_person_system')) {
      await this.$router.push({ name: 'forbidden' })
    }

    this.personEntity = person

    this.setAccount(this.personEntity)
  }

  async mounted () {
    this.$refs.form?.resetValidation()

    const { getFoundPerson } = this
    if (!getFoundPerson) {
      const { personIdComputed } = this
      if (personIdComputed) {
        await this.getPersonById(parseInt(personIdComputed))
      }
    }
  }

  setAccount (person: Person) {
    this.accountHolder = `${person?.fullName} ${person?.uid ? `/ ${person?.uid}` : ''}`
  }
}
