
  import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
  import { CountryData } from '@/utils/general'
  import CountrySelector from '@/components/core/input/CountrySelector.vue'

@Component({
  components: {
    CountrySelector,
  },
})
  export default class GPhone extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ default: false }) disabled!: boolean;

  $refs!: {
    form: HTMLFormElement
  };

  selectedCountry: CountryData | null = null;
  phoneNumber = '';
  valid = false;
  countryData: CountryData[] = [
    // Chile primero
    {
      name: 'Chile',
      code: 'CL',
      flag: require('@/components/icons/flags/Chile.svg'),
      prefix: '+56',
      maxDigits: 9,
    },
    // Países de la región alrededor de Chile
    {
      name: 'Argentina',
      code: 'AR',
      flag: require('@/components/icons/flags/Argentina.svg'),
      prefix: '+54',
      maxDigits: 10,
    },
    {
      name: 'Bolivia',
      code: 'BO',
      flag: require('@/components/icons/flags/Bolivia.svg'),
      prefix: '+591',
      maxDigits: 8,
    },
    {
      name: 'Perú',
      code: 'PE',
      flag: require('@/components/icons/flags/Peru.svg'),
      prefix: '+51',
      maxDigits: 9,
    },
    {
      name: 'Paraguay',
      code: 'PY',
      flag: require('@/components/icons/flags/Paraguay.svg'),
      prefix: '+595',
      maxDigits: 9,
    },
    {
      name: 'Uruguay',
      code: 'UY',
      flag: require('@/components/icons/flags/Uruguay.svg'),
      prefix: '+598',
      maxDigits: 8,
    },
    {
      name: 'Brasil',
      code: 'BR',
      flag: require('@/components/icons/flags/Brasil.svg'),
      prefix: '+55',
      maxDigits: 11,
    },
    {
      name: 'Ecuador',
      code: 'EC',
      flag: require('@/components/icons/flags/Ecuador.svg'),
      prefix: '+593',
      maxDigits: 9,
    },
    {
      name: 'Colombia',
      code: 'CO',
      flag: require('@/components/icons/flags/Colombia.svg'),
      prefix: '+57',
      maxDigits: 10,
    },
    {
      name: 'Venezuela',
      code: 'VE',
      flag: require('@/components/icons/flags/Venezuela.svg'),
      prefix: '+58',
      maxDigits: 10,
    },

    // Países del Caribe
    {
      name: 'Cuba',
      code: 'CU',
      flag: require('@/components/icons/flags/Cuba.svg'),
      prefix: '+53',
      maxDigits: 8,
    },
    {
      name: 'Haití',
      code: 'HT',
      flag: require('@/components/icons/flags/Haiti.svg'),
      prefix: '+509',
      maxDigits: 8,
    },
    // Otros países (ejemplos)
    {
      name: 'Estados Unidos',
      code: 'US',
      flag: require('@/components/icons/flags/Eua.svg'),
      prefix: '+1',
      maxDigits: 10,
    },
    {
      name: 'México',
      code: 'MX',
      flag: require('@/components/icons/flags/Mexico.svg'),
      prefix: '+52',
      maxDigits: 10,
    },
    {
      name: 'España',
      code: 'ES',
      flag: require('@/components/icons/flags/Spain.svg'),
      prefix: '+34',
      maxDigits: 9,
    },
  ];

  mounted () {
    if (this.$refs?.form?.resetValidation) this.$refs.form?.resetValidation()
  }

  get computedRules () {
    const { required, phoneNumber, selectedCountry } = this
    let baseRules = []

    // Regla para el campo requerido
    if (required) {
      baseRules.push((v: string) => !!v || 'Este campo es requerido')
    }

    // Regla para validar la longitud del número de teléfono si tiene valor
    if (phoneNumber.length > 0 && selectedCountry && selectedCountry.maxDigits) {
      baseRules.push((v: string) => {
        const digitsOnly = v.replace(/\D/g, '') // Remover caracteres no numéricos
        const expectedDigits = selectedCountry.maxDigits
        if (digitsOnly.length < expectedDigits) {
          return `El número debe tener ${expectedDigits} dígitos`
        }
        return true
      })
    }

    if (!required && !phoneNumber.length) {
      baseRules = []
    }

    this.$emit('error', Boolean(baseRules.length && baseRules.some(rule => rule(phoneNumber) !== true)))
    return baseRules
  }

  get phoneMask () {
    const maxDigits = this.selectedCountry ? this.selectedCountry.maxDigits : 10
    const mask = ''.padEnd(maxDigits, '#')
    return {
      mask,
      tokens: {
        '#': { pattern: /[0-9]/ },
      },
      kind: 'both',
    }
  }

  onInput (value: string) {
    this.phoneNumber = value
    this.emitInput()
  }

  @Emit('input')
  emitInput () {
    const prefix = this.selectedCountry ? this.selectedCountry.prefix : ''

    if (!this.phoneNumber?.length) return null
    return `${prefix}${this.phoneNumber}`
  }

  @Watch('value', { immediate: true })
  onValueChange (value: string) {
    const { countryData } = this
    if (value) {
      // Encontrar el país coincidente basado en el prefijo
      const matchingCountry = countryData.find(country => value.startsWith(country.prefix))
      if (matchingCountry) {
        this.selectedCountry = matchingCountry
        this.phoneNumber = value.slice(matchingCountry.prefix.length)
      } else {
        // Si no se encuentra un país coincidente, restablecer al valor predeterminado
        this.selectedCountry = countryData?.[0]
        this.phoneNumber = value
      }
    } else {
      // Si el valor está vacío, restablecer los campos
      this.selectedCountry = countryData?.[0]
      this.phoneNumber = ''
    }
  }
  }
